.App{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color : rgb(251, 226, 194);
}
  h2{
    padding: 20px 20px 20px;
   }

