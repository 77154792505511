.box{
    display:flex;
    justify-content: center; 
    align-content: center;
    }
  .accordion{
    width: 500px;
    
  }
  .item{
    background-image: radial-gradient( circle farthest-corner at 22.4% 21.7%, rgba(4,189,228,1) 0%, rgba(2,83,185,1) 100.2% );
    margin-bottom: 5px;
    padding: 10px 20px;
  }
  .title{
    color: rgb(18, 17, 18);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px 10px;
    }
  .content{
    color: black;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
   }
  .content.show{
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(0,1,0,1);
    background-color: white;
   }
   
   
  